// eslint-disable-next-line import/order
import {
  IProductCountryScansMapItem,
  IProductCountryScansReportItem,
  IRule,
  ISuspiciousProtectCodeReportAlert,
  ISuspiciousProtectCodeReportCountries,
  ISuspiciousProtectCodeReportLocations
} from '@/modules/brand-protection/brand-protection.interface';
import { IProduct } from '@/modules/product/product.interface';
import { MutationTree } from 'vuex';
import { BrandProtectionState } from './types';
import { ISuspiciousProtectCodeAlertReport } from '@connect-saas/types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<BrandProtectionState> = {
  UPDATE_BRAND_PROTECTION_RULES(state, brand_protection_rules: IRule[]) {
    state.brand_protection_rules = brand_protection_rules;
  },
  SELECTED_BRAND_PROTECTION_RULE(state, selected_brand_protection_rule: IRule) {
    state.selected_brand_protection_rule = selected_brand_protection_rule;
  },
  UPDATE_SCANS_OVER_TIME_REPORT(
    state,
    scans_over_time_report: ISuspiciousProtectCodeReportAlert[]
  ) {
    state.scans_over_time_report = scans_over_time_report;
  },
  UPDATE_SCANS_OVER_TIME_LOCATIONS(
    state,
    scan_over_time_locations: ISuspiciousProtectCodeReportLocations
  ) {
    state.scan_over_time_locations = scan_over_time_locations;
  },
  UPDATE_SCANS_OVER_TIME_COUNTRIES(
    state,
    scans_over_time_countries: ISuspiciousProtectCodeReportCountries
  ) {
    state.scans_over_time_countries = scans_over_time_countries;
  },
  SELECTED_ALERT(state, selected_alert: ISuspiciousProtectCodeAlertReport) {
    state.selected_alert = selected_alert;
  },
  UPDATE_PAGINATED_PRODUCTS(state, paginated_products: IProduct[]) {
    state.paginated_products = paginated_products;
  },
  UPDATE_PRODUCT_COUNTRY_SCANS_REPORT(
    state,
    product_country_scans_report: IProductCountryScansReportItem[]
  ) {
    state.product_country_scans_report = product_country_scans_report;
  },
  UPDATE_PRODUCT_COUNTRY_SCANS_MAP(
    state,
    product_country_scans_map: IProductCountryScansMapItem[]
  ) {
    state.product_country_scans_map = product_country_scans_map;
  }
};
