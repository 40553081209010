import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import { ISearchablePageTheme } from '@connect-saas/modules/search';
import { MutationTree } from 'vuex';
import { IPageThemeState } from './types';
import { IPageTheme } from '@connect-saas/types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IPageThemeState> = {
  UPDATE_PAGINATED_THEMES(
    state: IPageThemeState,
    paginated_themes: PaginatedResponse<ISearchablePageTheme>
  ) {
    state.paginated_themes = paginated_themes;
  },
  SELECTED_THEME(state: IPageThemeState, selected_theme: IPageTheme) {
    state.selected_theme = selected_theme;
  },
  UPDATE_SELECTED_THEME(state: IPageThemeState, update_selected_theme: IPageTheme) {
    state.update_selected_theme = update_selected_theme;
  },
  UPDATE_SELECTED_THEME_FOR_LOOKUP(
    state: IPageThemeState,
    update_selected_theme_for_lookup: IPageTheme
  ) {
    state.update_selected_theme_for_lookup = update_selected_theme_for_lookup;
  },
  NEWLY_CREATED_THEME_FOR_EDIT_QR(
    state: IPageThemeState,
    newly_created_theme_for_edit_qr: IPageTheme
  ) {
    state.newly_created_theme_for_edit_qr = newly_created_theme_for_edit_qr;
  }
};
