import { IRootState } from '@/modules/common/common.interface';
import { GetterTree } from 'vuex';
import { IAccountState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<IAccountState, IRootState> = {
  form_allowance_remaining(state: IAccountState): number | null {
    const remaining: number | null = state.account_allowance?.forms?.remaining;
    return remaining;
  },
  short_url_allowance_remaining(state: IAccountState): number | null {
    const remaining: number | null = state.account_allowance?.shortUrls?.remaining;
    return remaining;
  },
  page_allowance_remaining(state: IAccountState): number | null {
    const remaining: number | null = state.account_allowance?.pages?.remaining;
    return remaining;
  },
  page_allowance_usage(state: IAccountState): number | null {
    const usage: number | null = state.account_allowance?.pages?.usage;
    return usage;
  },
  page_theme_allowance_remaining(state: IAccountState): number | null {
    const remaining: number | null = state.account_allowance?.pageTheme?.remaining;
    return remaining;
  },
  user_allowance_remaining(state: IAccountState): boolean {
    const remaining: number | null = state.account_allowance?.users?.remaining;
    return remaining === null || remaining > 0;
  },
  user_allowance(state: IAccountState): number | null {
    const allowance: number | null = state.account_allowance?.users?.allowance;
    return allowance;
  }
};
