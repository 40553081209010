import { useRemoveCookie } from '@/composables/cookies';
import { ERootMutations } from '@/modules/common/common.enum';
import { IRootState } from '@/modules/common/common.interface';
import { IToastMessage } from '@/modules/error/error.interface';
import { PureAbility } from '@casl/ability/dist/types/PureAbility';
import { useCookies } from '@vueuse/integrations/useCookies';
import { MutationTree } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IRootState> = {
  [ERootMutations.CLEAR_ALL_STORAGE_DATA](state) {
    state.auth.isLoggedIn = false;
    state.auth.accessToken = null;
    state.auth.refreshToken = null;
    localStorage.removeItem('lastVisitedRoute');
    useRemoveCookie();
  },
  [ERootMutations.SHOW_TOAST_MESSAGE](state, toast: IToastMessage) {
    state.show_loading = false;
    state.toasts.push(toast);
  },
  [ERootMutations.TOGGLE_SIDE_NAVBAR](state, drawer: boolean) {
    state.drawer = drawer;
  },
  [ERootMutations.SET_TOGGLE_SIDE_NAVBAR](state, set_drawer: boolean) {
    state.set_drawer = set_drawer;
  },
  [ERootMutations.TEMPORARY_TOGGLE_SIDE_NAVBAR](
    state,
    { setDrawer, useTemp }: { setDrawer: boolean; useTemp: boolean }
  ) {
    if (useTemp) {
      state.set_drawer = state.temp_drawer;
      state.temp_drawer = false;
    } else {
      state.temp_drawer = state.drawer;
      state.set_drawer = setDrawer;
    }
  },
  [ERootMutations.SET_LOADING](state, payload) {
    state.show_loading = payload === true;
  },
  [ERootMutations.SHOW_SIDEBAR_POPUP](state, data: any) {
    state.popup = true;
    state.popup_type = data.component;
    state.popup_name = data.data.name;
    state.popup_data = data.data;
  },
  [ERootMutations.HIDE_POPUP](state) {
    state.popup = false;
  },
  [ERootMutations.UPDATE_AUTH](state, auth) {
    state.auth = auth;
  },
  [ERootMutations.SHOW_QUICK_ADD_POPUP](state, add_popup) {
    state.quick_popup = true;
    state.is_edit_mode = false;
    state.is_add_mode = true;
    state.is_view_mode = false;
    state.quick_popup_data = add_popup?.data ?? {};
    state.quick_popup_title = add_popup.title;
    state.quick_popup_type = add_popup.type;
    state.quick_popup_width = add_popup?.width ?? null;
    state.quick_popup_back = add_popup?.back ?? null;
  },
  [ERootMutations.HIDE_QUICK_ADD_POPUP](state) {
    state.quick_popup = false;
    state.quick_popup_data = null;
    state.is_add_mode = false;
    state.is_edit_mode = false;
    state.quick_popup_type = null;
    state.quick_popup_width = null;
    state.quick_popup_back = null;
  },
  [ERootMutations.SHOW_EDIT_POPUP](state, edit_popup) {
    state.quick_popup = true;
    state.is_edit_mode = true;
    state.is_add_mode = false;
    state.is_view_mode = false;
    state.quick_popup_title = edit_popup.title;
    state.quick_popup_type = edit_popup.type;
    state.quick_popup_data = edit_popup.data;
    state.quick_popup_width = edit_popup?.width ?? null;
    state.quick_popup_back = edit_popup?.back ?? null;
  },
  [ERootMutations.SHOW_QUICK_VIEW_POPUP](state, view_popup) {
    state.quick_popup = true;
    state.is_edit_mode = false;
    state.is_add_mode = false;
    state.is_view_mode = true;
    state.quick_popup_data = view_popup.data;
    state.quick_popup_title = view_popup.title;
    state.quick_popup_type = view_popup.type;
  },
  [ERootMutations.SET_ABILITY](state: IRootState, ability: PureAbility) {
    state.ability = ability;
  },
  [ERootMutations.SET_AUTH_STATUS_SUCCESS](state: IRootState, status: string) {
    state.status = status;
    state.auth.isLoggedIn = true;
  },
  [ERootMutations.SET_AUTH_STATUS_SUCCESS_BUT_NO_ROLE](state) {
    state.status = 'successButNoRole';
  },
  [ERootMutations.SET_AUTH_STATUS_ERR](state) {
    state.status = 'error';
  },
  [ERootMutations.RESET_AUTH_STATUS](state) {
    state.status = '';
    state.auth.isLoggedIn = false;
  },
  [ERootMutations.SET_AUTH_STATUS_LOADING](state) {
    state.status = 'loading';
  },
  [ERootMutations.SET_ERR](state, payload) {
    state.error = payload;
  },
  [ERootMutations.SET_FRIENDLY_ERR_MSG](state, payload: string) {
    state.friendly_error_message = payload;
  },
  [ERootMutations.GET_DATE](state, payload) {
    state.get_date = payload;
  },
  [ERootMutations.GET_HOST_NAME](state) {
    const host: string = window && window.location && window.location.hostname;
    const cookies = useCookies(['locale']);
    const isEcocanHost = cookies?.get('is-ecocan-host');
    if (host.indexOf('ecocanafrica.com') > -1 || isEcocanHost) {
      state.is_ecocan_host = true;
    }
  },
  [ERootMutations.IS_REFRESHED_TOKEN](state: IRootState, is_refreshed_token: boolean) {
    state.is_refreshed_token = is_refreshed_token;
  },
  [ERootMutations.IS_FIRST_LOAD](state: IRootState, is_first_load: boolean) {
    state.is_first_load = is_first_load;
  },
  [ERootMutations.REFRESH_RETRY_COUNT](state: IRootState, refresh_retry_count: number) {
    state.refresh_retry_count = refresh_retry_count;
  }
};
