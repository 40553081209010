/* eslint-disable @typescript-eslint/no-use-before-define */
import { decodedJwt } from '@/composables/cookies';
import { ECard, ECardLink } from '@/views/private/integration/types/enum';
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
  createRouter,
  createWebHistory
} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/authentication',
    name: 'Authentication',
    component: () => import('@/layout/LoginLayout.vue'),
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/public/login/Login.vue'),
        beforeEnter: verifyPublicPage,
        meta: { title: 'Sign in', requiresAuth: false, gtm: 'login' }
      },
      {
        path: '/login/restore',
        name: 'Forgot password',
        component: () => import('@/views/public/forgot-password/ForgotPassword.vue'),
        beforeEnter: verifyPublicPage,
        meta: {
          title: 'Forgot password',
          requiresAuth: false,
          gtm: 'forgotpassword'
        }
      },
      {
        path: '/account-recovery',
        name: 'Account Recovery',
        component: () => import('@/views/public/account-recovery/AccountRecovery.vue'),
        beforeEnter: verifyPublicPage,
        meta: {
          title: 'Account Recovery',
          requiresAuth: false,
          gtm: 'accountrecovery'
        }
      },
      {
        path: '/signup/:source?',
        name: 'Sign up',
        component: () => import('@/views/public/signup/Index.vue'),
        beforeEnter: verifyPublicPage,
        meta: { title: 'Sign up', requiresAuth: false, gtm: 'signup' }
      },
      {
        path: '/email-verification',
        name: 'Email Verification',
        component: () => import('@/views/public/login/Login.vue'),
        beforeEnter: verifyPublicPage,
        meta: {
          title: 'Email Verification',
          requiresAuth: false,
          gtm: 'email-verification'
        }
      }
    ]
  },
  {
    path: '/login/canva',
    name: 'Canva Login',
    component: () => import('@/views/public/login/canva/Login.vue'),
    beforeEnter: verifyPublicPage,
    meta: { title: 'Canva Login', requiresAuth: false, gtm: 'canva-login' }
  },
  {
    path: '/login/canva/link',
    name: 'Link Your Account',
    component: () => import('@/views/private/canva/LinkAccount.vue'),
    beforeEnter: verifyPrivatePage,
    meta: {
      title: 'Link Your Account',
      requiresAuth: true,
      gtm: 'link-account'
    }
  },
  {
    path: '/signup/canva',
    name: 'Canva Sign up',
    component: () => import('@/views/public/signup/canva/Signup.vue'),
    beforeEnter: verifyPublicPage,
    meta: { title: 'Canva Sign up', requiresAuth: false, gtm: 'canva-signup' }
  },
  {
    path: '/signup/complete',
    name: 'Signup Complete',
    component: () => import('@/views/public/signup/Index.vue'),
    children: [
      {
        path: ':source?',
        name: 'Signup Complete Source',
        component: () => import('@/views/public/signup/Index.vue'),
        meta: { title: 'Signup Complete', requiresAuth: false, gtm: 'signup-complete' }
      }
    ]
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../layout/Layout.vue'),
    redirect: '/dashboard',
    children: [
      {
        path: '/account/profile',
        name: 'Profile',
        component: () => import('@/views/private/account/Profile.vue'),
        beforeEnter: verifyPrivatePage,
        meta: { title: 'Profile', requiresAuth: true, gtm: 'account-profile' }
      },
      {
        path: '/email-change-verification',
        name: 'Email Change Verification',
        component: () => import('@/views/private/account/Profile.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Email Change Verification',
          requiresAuth: true,
          gtm: 'email-change-verification'
        }
      },
      {
        path: '/account/settings',
        name: 'Account Settings',
        component: () => import('@/views/private/account/AccountSettings.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Account Settings',
          requiresAuth: true,
          gtm: 'account-settings'
        }
      },
      {
        path: '/account/transfer-accept',
        name: 'Account Transfer Accept',
        component: () => import('@/views/private/account/AccountSettings.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Account Transfer Accept',
          requiresAuth: true,
          gtm: 'account-transfer-accept'
        }
      },
      {
        path: '/account/billing/:pathMatch(.*)*',
        name: 'Billing Management',
        component: () => import('@/views/private/account/billing/Billing.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Billing Management',
          requiresAuth: true,
          gtm: 'billing-management'
        }
      },
      {
        path: '/account/subscription',
        name: 'Subscription',
        component: () => import('@/views/private/account/AccountSettings.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Subscription',
          requiresAuth: true,
          gtm: 'subscription'
        }
      },
      {
        path: '/dashboard',
        name: 'Overview',
        component: () => import('@/views/private/dashboard/Index.vue'),
        beforeEnter: verifyPrivatePage,
        meta: { title: 'Overview', requiresAuth: true, gtm: 'overview' }
      },
      {
        path: '/product',
        children: [
          {
            path: '/product',
            name: 'Product Inventory',
            component: () => import('@/views/private/product/Product.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Product Inventory',
              requiresAuth: true,
              gtm: 'product-inventory'
            }
          },
          {
            path: '/product/new',
            name: 'New Product',
            component: () => import('@/views/private/product/AddNewProduct.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'New Product',
              requiresAuth: true,
              gtm: 'new-product',
              breadcrumb: [
                { name: 'Product Inventory', routeName: 'Product Inventory' },
                { name: 'New Product', routeName: 'New Product' }
              ]
            }
          },
          {
            path: '/product/:id',
            name: 'Edit Product',
            component: () => import('@/views/private/product/AddNewProduct.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Edit Product',
              requiresAuth: true,
              gtm: 'edit-product',
              breadcrumb: [
                { name: 'Product Inventory', routeName: 'Product Inventory' },
                { name: 'Edit Product', routeName: 'Edit Product' }
              ]
            }
          }
        ]
      },
      {
        path: '/protect-code/tech-batch/qr',
        name: 'QR Batch Manager',
        component: () => import('@/views/private/tech-batch/qr/QRBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'QR Batch Manager',
          requiresAuth: true,
          gtm: 'batch-manager'
        }
      },
      {
        path: '/protect-code/tech-batch/qr/new',
        name: 'New Code',
        component: () => import('@/views/private/tech-batch/qr/AddNewQRBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'New Code',
          requiresAuth: true,
          gtm: 'new-code',
          breadcrumb: [
            { name: 'QR Batch Manager', routeName: 'QR Batch Manager' },
            { name: 'New Code', routeName: 'New Code' }
          ]
        }
      },
      {
        path: '/protect-code/tech-batch/qr/:id',
        name: 'Edit Code',
        component: () => import('@/views/private/tech-batch/qr/AddNewQRBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Code',
          requiresAuth: true,
          gtm: 'edit-code',
          breadcrumb: [
            { name: 'QR Batch Manager', routeName: 'QR Batch Manager' },
            { name: 'Edit Code', routeName: 'Edit Code' }
          ]
        }
      },
      {
        path: '/protect-code/tech-batch/qr/code-search',
        name: 'Code Search',
        component: () => import('@/views/private/tech-batch/qr/CodeSearch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Code Search',
          requiresAuth: true,
          gtm: 'code-search'
        }
      },
      {
        path: '/protectcode-plus/batch-manager',
        name: 'Batch Manager',
        component: () =>
          import('@/views/private/tech-batch/protectcode-plus/ProtectCodePlusBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Batch Manager',
          requiresAuth: true,
          gtm: 'batch-manager'
        }
      },
      {
        path: '/protectcode-plus/batch-manager/new',
        name: 'New ProtectCode Plus',
        component: () =>
          import('@/views/private/tech-batch/protectcode-plus/AddNewProtectCodePlusBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'New ProtectCode Plus',
          requiresAuth: true,
          gtm: 'new-protectcode-plus',
          breadcrumb: [
            { name: 'Batch Manager', routeName: 'Batch Manager' },
            { name: 'New ProtectCode Plus', routeName: 'New ProtectCode Plus' }
          ]
        }
      },
      {
        path: '/protectcode-plus/batch-manager/:id',
        name: 'Edit ProtectCode Plus',
        component: () =>
          import('@/views/private/tech-batch/protectcode-plus/AddNewProtectCodePlusBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit ProtectCode Plus',
          requiresAuth: true,
          gtm: 'edit-protectcode-plus',
          breadcrumb: [
            { name: 'Batch Manager', routeName: 'Batch Manager' },
            {
              name: 'Edit ProtectCode Plus',
              routeName: 'Edit ProtectCode Plus'
            }
          ]
        }
      },
      {
        path: '/campaign',
        name: 'Campaign',
        component: () => import('@/views/private/campaign/Campaign.vue'),
        beforeEnter: verifyPrivatePage,
        meta: { title: 'Campaign', requiresAuth: true, gtm: 'campaign' }
      },
      {
        path: '/campaign/new',
        name: 'New Campaign',
        component: () => import('@/views/private/campaign/AddNewCampaign.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'New Campaign',
          requiresAuth: true,
          gtm: 'new-campaign',
          breadcrumb: [
            { name: 'Campaign', routeName: 'Campaign' },
            { name: 'New Campaign', routeName: 'New Campaign' }
          ]
        }
      },
      {
        path: '/campaign/:id',
        name: 'Edit Campaign',
        component: () => import('@/views/private/campaign/AddNewCampaign.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Campaign',
          requiresAuth: true,
          gtm: 'edit-campaign',
          breadcrumb: [
            { name: 'Campaign', routeName: 'Campaign' },
            { name: 'Edit Campaign', routeName: 'Edit Campaign' }
          ]
        }
      },
      {
        path: '/audience',
        name: 'Audience',
        component: () => import('@/views/private/audience/Audience.vue'),
        beforeEnter: verifyPrivatePage,
        meta: { title: 'Audience', requiresAuth: true, gtm: 'audience' }
      },
      {
        path: '/reports',
        name: 'Reports',
        component: () => import('@/views/private/reports/Reports.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Reports',
          requiresAuth: true,
          gtm: 'reports',
          breadcrumb: [{ name: 'Reports', routeName: 'Reports' }]
        }
      },
      {
        path: '/report/new',
        name: 'New Report',
        component: () => import('@/views/private/reports/AddNewReport.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'New Report',
          requiresAuth: true,
          gtm: 'new-report',
          breadcrumb: [
            { name: 'Reports', routeName: 'Reports' },
            { name: 'New Report', routeName: 'New Report' }
          ]
        }
      },
      {
        path: '/report/:id',
        name: 'Edit Report',
        component: () => import('@/views/private/reports/AddNewReport.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Report',
          requiresAuth: true,
          gtm: 'edit-report',
          breadcrumb: [
            { name: 'Reports', routeName: 'Reports' },
            { name: 'Edit Report', routeName: 'Edit Report' }
          ]
        }
      },
      {
        path: '/report/:id/details',
        name: 'Report Details',
        component: () => import('@/views/private/reports/ReportDetails.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Report Details',
          requiresAuth: true,
          gtm: 'report-details',
          breadcrumb: [
            { name: 'Reports', routeName: 'Reports' },
            { name: 'Report Details', routeName: 'Report Details' }
          ]
        }
      },
      {
        path: '/tracer',
        name: 'Tracer Analytics',
        component: () => import('@/views/private/tracer/Analytics.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Tracer Analytics',
          requiresAuth: true,
          gtm: 'traceranalytics'
        }
      },
      {
        path: '/motifmicro',
        name: 'MotifMicro Analytics',
        component: () => import('@/views/private/motifmicro/Analytics.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'MotifMicro Analytics',
          requiresAuth: true,
          gtm: 'motifmicroanalytics'
        }
      },
      {
        path: '/media-library/:id?',
        name: 'Media Library',
        component: () => import('../views/private/media-library/MediaLibrary.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Media Library',
          requiresAuth: true,
          gtm: 'media-library'
        }
      },
      {
        path: '/forms',
        children: [
          {
            path: '/forms',
            name: 'Form',
            component: () => import('@/views/private/form/Form.vue'),
            beforeEnter: verifyPrivatePage,
            meta: { title: 'Form', requiresAuth: true, gtm: 'form' }
          },
          {
            path: '/forms/new',
            name: 'New Form',
            component: () => import('@/views/private/form/AddNewForm.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'New Form',
              requiresAuth: true,
              gtm: 'new-form',
              breadcrumb: [
                { name: 'Form', routeName: 'Form' },
                { name: 'New Form', routeName: 'New Form' }
              ]
            }
          },
          {
            path: '/forms/:id',
            name: 'Edit Form',
            component: () => import('@/views/private/form/AddNewForm.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Edit Form',
              requiresAuth: true,
              gtm: 'edit-form',
              breadcrumb: [
                { name: 'Form', routeName: 'Form' },
                { name: 'Edit Form', routeName: 'Edit Form' }
              ]
            }
          },
          {
            path: '/forms/:id/submission/:link?',
            name: 'Form Submission',
            component: () => import('@/views/private/form/Submission.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Form Submission',
              requiresAuth: true,
              gtm: 'form-submission',
              breadcrumb: [
                { name: 'Form', routeName: 'Form' },
                { name: 'Form Submission', routeName: 'Form Submission' }
              ]
            }
          }
        ]
      },
      {
        path: '/protect-code/tech-batch/qr',
        name: 'QR Batch Manager',
        component: () => import('@/views/private/tech-batch/qr/QRBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'QR Batch Manager',
          requiresAuth: true,
          gtm: 'batch-manager'
        }
      },
      {
        path: '/protect-code/tech-batch/qr/new',
        name: 'New Code',
        component: () => import('@/views/private/tech-batch/qr/AddNewQRBatch.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'New Code',
          requiresAuth: true,
          gtm: 'new-code',
          breadcrumb: [
            { name: 'QR Batch Manager', routeName: 'QR Batch Manager' },
            { name: 'New Code', routeName: 'New Code' }
          ]
        }
      },
      {
        path: '/qr',
        name: 'ConnectQR',
        redirect: '/qr/links',
        children: [
          {
            path: '/qr/links/generate',
            name: 'Create QR',
            component: () => import('@/views/private/qr-generator/GenerateQr.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Create QR',
              requiresAuth: true,
              gtm: 'new-qr-code'
            }
          },
          {
            path: '/qr/links',
            children: [
              {
                path: '/qr/links',
                name: 'My ConnectQR',
                component: () => import('@/views/private/qr-generator/QrCode.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'My ConnectQR',
                  requiresAuth: true,
                  gtm: 'my-qr-code'
                }
              },
              {
                path: '/qr/links/generate/:id',
                name: 'Edit QR',
                component: () => import('@/views/private/qr-generator/GenerateQr.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'Edit QR',
                  requiresAuth: true,
                  gtm: 'edit-qr-code'
                }
              },
              {
                path: '/qr/links/generate/connect-qr/:id',
                name: 'Edit Connect QR',
                component: () => import('@/views/private/qr-generator/GenerateQr.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'Edit Connect QR',
                  requiresAuth: true,
                  gtm: 'edit-connect-qr-code'
                }
              },
              {
                path: '/qr/links/generate/connect-qr/:id/customise',
                name: 'Customise Connect QR',
                component: () => import('@/views/private/qr-generator/components/Customise.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'Customise Connect QR',
                  requiresAuth: true,
                  gtm: 'customise-connect-qr'
                }
              },
              {
                path: '/qr/links/generate/connect-qr/:id/ai',
                name: 'AI QR Code',
                component: () => import('@/views/private/qr-generator/components/ai-qr/Ai.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'AI QR Code',
                  requiresAuth: true,
                  gtm: 'ai-qr-code'
                }
              },
              {
                path: '/qr/links/generate/static-qr/:id',
                name: 'Edit Static QR',
                component: () => import('@/views/private/qr-generator/GenerateQr.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'Edit Static QR',
                  requiresAuth: true,
                  gtm: 'edit-static-qr-code'
                }
              },
              {
                path: '/qr/links/generate/static-qr/:id/customise',
                name: 'Customise Static QR',
                component: () => import('@/views/private/qr-generator/components/Customise.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'Customise QR',
                  requiresAuth: true,
                  gtm: 'customise-static-qr'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/url',
        name: 'URL Shortener',
        redirect: '/url-shortener',
        children: [
          {
            path: '/url-shortener/generate',
            name: 'Create URL',
            component: () => import('@/views/private/url-shortener/AddNewUrl.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Create URL',
              requiresAuth: true,
              gtm: 'new-url'
            }
          },
          {
            path: '/url-shortener',
            children: [
              {
                path: '/url-shortener',
                name: 'My Shortened URLs',
                component: () => import('@/views/private/url-shortener/UrlShortener.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'My Shortened URLs',
                  requiresAuth: true,
                  gtm: 'my-shotended-urls'
                }
              },
              {
                path: '/url-shortener/generate/:id',
                name: 'Edit URL',
                component: () => import('@/views/private/url-shortener/AddNewUrl.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'Edit URL',
                  requiresAuth: true,
                  gtm: 'edit-url'
                }
              }
            ]
          },
          {
            path: '/url-shortener/analytics',
            name: 'URL Analytics',
            component: () => import('@/views/private/qr-generator/Analytics.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'URL Analytics',
              requiresAuth: true,
              gtm: 'url-analytics'
            }
          },
          {
            path: '/url-shortener/:id/analytics',
            name: 'URL Analytics Details',
            component: () => import('@/views/private/qr-generator/AnalyticsDetails.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'URL Analytics Details',
              requiresAuth: true,
              gtm: 'url-analytics-details'
            }
          }
        ]
      },
      {
        path: '/integration',
        name: 'Integrations',
        redirect: '/integrations',
        children: [
          {
            path: '/integrations',
            children: [
              {
                path: '/integrations',
                name: 'Integration',
                component: () => import('@/views/private/integration/Integration.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: 'Integrations',
                  requiresAuth: true,
                  gtm: 'integrations'
                }
              },
              {
                path: `/integrations/${ECardLink.GOOGLE_ADWORDS}`,
                name: ECard.GOOGLE_ADWORDS,
                component: () => import('@/views/private/integration/IntegrationForm.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: ECard.GOOGLE_ADWORDS,
                  requiresAuth: true,
                  gtm: ECardLink.GOOGLE_ADWORDS,
                  breadcrumb: [
                    {
                      name: 'Integrations',
                      routeName: 'Integrations'
                    },
                    {
                      name: ECard.GOOGLE_ADWORDS,
                      routeName: ECard.GOOGLE_ADWORDS
                    }
                  ]
                }
              },
              {
                path: `/integrations/${ECardLink.GOOGLE_ANALYTICS}`,
                name: ECard.GOOGLE_ANALYTICS,
                component: () => import('@/views/private/integration/IntegrationForm.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: ECard.GOOGLE_ANALYTICS,
                  requiresAuth: true,
                  gtm: ECardLink.GOOGLE_ANALYTICS,
                  breadcrumb: [
                    {
                      name: 'Integrations',
                      routeName: 'Integrations'
                    },
                    {
                      name: ECard.GOOGLE_ANALYTICS,
                      routeName: ECard.GOOGLE_ANALYTICS
                    }
                  ]
                }
              },
              {
                path: `/integrations/${ECardLink.GOOGLE_TAG_MANAGER}`,
                name: ECard.GOOGLE_TAG_MANAGER,
                component: () => import('@/views/private/integration/IntegrationForm.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: ECard.GOOGLE_TAG_MANAGER,
                  requiresAuth: true,
                  gtm: ECardLink.GOOGLE_TAG_MANAGER,
                  breadcrumb: [
                    {
                      name: 'Integrations',
                      routeName: 'Integrations'
                    },
                    {
                      name: ECard.GOOGLE_TAG_MANAGER,
                      routeName: ECard.GOOGLE_TAG_MANAGER
                    }
                  ]
                }
              },
              {
                path: `/integrations/${ECardLink.META_PIXEL}`,
                name: ECard.META_PIXEL,
                component: () => import('@/views/private/integration/IntegrationForm.vue'),
                beforeEnter: verifyPrivatePage,
                meta: {
                  title: ECard.META_PIXEL,
                  requiresAuth: true,
                  gtm: ECardLink.META_PIXEL,
                  breadcrumb: [
                    {
                      name: 'Integrations',
                      routeName: 'Integrations'
                    },
                    {
                      name: ECard.META_PIXEL,
                      routeName: ECard.META_PIXEL
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        path: '/qr/analytics',
        name: 'QR Analytics',
        component: () => import('@/views/private/qr-generator/Analytics.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'QR Analytics',
          requiresAuth: true,
          gtm: 'qr-analytics'
        }
      },
      {
        path: '/qr/:id/analytics',
        name: 'QR Analytics Details',
        component: () => import('@/views/private/qr-generator/AnalyticsDetails.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'QR Analytics Details',
          requiresAuth: true,
          gtm: 'qr-analytics-details'
        }
      },
      {
        path: '/pages',
        children: [
          {
            path: '/pages',
            name: 'Page Builder',
            component: () => import('@/views/private/page/Page.vue'),
            beforeEnter: verifyPrivatePage,
            meta: { title: 'Page', requiresAuth: true, gtm: 'page' }
          },
          {
            path: '/pages/new',
            name: 'New Page',
            component: () => import('@/views/private/page/AddNewPage.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'New Page',
              requiresAuth: true,
              gtm: 'new-page',
              breadcrumb: [
                { name: 'Page Builder', routeName: 'Page Builder' },
                { name: 'New Page', routeName: 'New Page' }
              ]
            }
          },
          {
            path: '/pages/:id',
            name: 'Edit Page',
            component: () => import('@/views/private/page/AddNewPage.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Edit Page',
              requiresAuth: true,
              gtm: 'edit-page',
              breadcrumb: [
                { name: 'Page Builder', routeName: 'Page Builder' },
                { name: 'Edit Page', routeName: 'Edit Page' }
              ]
            }
          }
        ]
      },
      {
        path: '/themes',
        children: [
          {
            path: '/themes',
            name: 'Theme Builder',
            component: () => import('@/views/private/theme/Theme.vue'),
            beforeEnter: verifyPrivatePage,
            meta: { title: 'Theme', requiresAuth: true, gtm: 'theme' }
          },
          {
            path: '/themes/new',
            name: 'New Theme',
            component: () => import('@/views/private/theme/AddNewTheme.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'New Theme',
              requiresAuth: true,
              gtm: 'new-theme',
              breadcrumb: [
                { name: 'Theme Builder', routeName: 'Theme Builder' },
                { name: 'New Theme', routeName: 'New Theme' }
              ]
            }
          },
          {
            path: '/themes/:id',
            name: 'Edit Theme',
            component: () => import('@/views/private/theme/AddNewTheme.vue'),
            beforeEnter: verifyPrivatePage,
            meta: {
              title: 'Edit Theme',
              requiresAuth: true,
              gtm: 'edit-theme',
              breadcrumb: [
                { name: 'Theme Builder', routeName: 'Theme Builder' },
                { name: 'Edit Theme', routeName: 'Edit Theme' }
              ]
            }
          }
        ]
      }
    ]
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('../layout/Layout.vue'),
    redirect: '/analytics',
    children: [
      {
        path: '/analytics',
        name: 'Analytics Detail',
        component: () => import('@/views/private/analytics/Analytics.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Analytics Detail',
          requiresAuth: true,
          gtm: 'analytics'
        }
      },
      {
        path: '/geographic',
        name: 'Geographic',
        component: () => import('@/views/private/analytics/geographic/Geographic.vue'),
        beforeEnter: verifyPrivatePage,
        meta: { title: 'Geographic', requiresAuth: true, gtm: 'geographic' }
      },
      {
        path: '/technology',
        name: 'Technology',
        component: () => import('@/views/private/analytics/technology/Technology.vue'),
        beforeEnter: verifyPrivatePage,
        meta: { title: 'Technology', requiresAuth: true, gtm: 'technology' }
      }
    ]
  },
  {
    path: '/brandprotection',
    name: 'Suspicious Codes',
    component: () => import('../layout/Layout.vue'),
    redirect: '/suspicious-codes',
    children: [
      {
        path: '/suspicious-codes',
        name: 'Suspicious Codes Detail',
        component: () => import('@/views/private/brand-protection/BrandProtection.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Suspicious Codes',
          requiresAuth: true,
          gtm: 'suspicious-codes',
          breadcrumb: [{ name: 'Suspicious Codes', routeName: 'Suspicious Codes' }]
        }
      },
      {
        path: '/country-report',
        name: 'Country report',
        component: () => import('@/views/private/brand-protection/details/CountryReport.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Country report',
          requiresAuth: true,
          gtm: 'country-report',
          breadcrumb: [
            { name: 'Suspicious Codes', routeName: 'Suspicious Codes Detail' },
            { name: 'Country report', routeName: 'Country report' }
          ]
        }
      },
      {
        path: '/suspicious-codes/:id',
        name: 'Suspicious Code',
        component: () =>
          import('@/views/private/brand-protection/details/AnalyticsSuspectCode.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Suspicious Code',
          requiresAuth: true,
          gtm: 'suspicious-codes',
          breadcrumb: [
            { name: 'Suspicious Codes', routeName: 'Suspicious Codes Detail' },
            { name: 'Suspicious Code', routeName: 'Suspicious Code' }
          ]
        }
      },
      {
        path: '/product/:id/details',
        name: 'Product Analytics',
        component: () => import('@/views/private/brand-protection/details/AnalyticsProduct.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Product Analytics',
          requiresAuth: true,
          gtm: 'product-analytics',
          breadcrumb: [
            { name: 'Analytics', routeName: 'Analytics' },
            { name: 'Product Analytics', routeName: 'Product Analytics' }
          ]
        }
      },
      {
        path: '/campaign/:id/details',
        name: 'Campaign Analytics',
        component: () => import('@/views/private/brand-protection/details/AnalyticsCampaign.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Campaign Analytics',
          requiresAuth: true,
          gtm: 'campaign-analytics',
          breadcrumb: [
            { name: 'Analytics', routeName: 'Analytics' },
            { name: 'Campaign Analytics', routeName: 'Campaign Analytics' }
          ]
        }
      },
      {
        path: '/geographic-monitoring',
        name: 'Geographic Monitoring',
        component: () =>
          import('@/views/private/brand-protection/geographic-monitoring/GeographicMonitoring.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Geographic Monitoring',
          requiresAuth: true,
          gtm: 'geographic-monitoring',
          breadcrumb: [
            { name: 'Suspicious Codes', routeName: 'Suspicious Codes Detail' },
            {
              name: 'Geographic Monitoring',
              routeName: 'Geographic Monitoring'
            }
          ]
        }
      },
      {
        path: '/monitoring-rules',
        name: 'Monitoring rules',
        component: () => import('@/views/private/brand-protection/rules/MonitoringRules.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Monitoring rules',
          requiresAuth: true,
          gtm: 'monitoring-rules',
          breadcrumb: [
            { name: 'Suspicious Codes', routeName: 'Suspicious Codes Detail' },
            { name: 'Monitoring rules', routeName: 'Monitoring rules' }
          ]
        }
      },
      {
        path: '/monitoring-rules/:id',
        name: 'Edit Monitoring rules',
        component: () => import('@/views/private/brand-protection/rules/AddNewRule.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Monitoring rules',
          requiresAuth: true,
          gtm: 'edit-monitoring-rules',
          breadcrumb: [
            { name: 'Suspicious Codes', routeName: 'Suspicious Codes Detail' },
            {
              name: 'Edit Monitoring rules',
              routeName: 'Edit Monitoring rules'
            }
          ]
        }
      },
      {
        path: '/new-rule',
        name: 'New rule',
        component: () => import('@/views/private/brand-protection/rules/AddNewRule.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'New rule',
          requiresAuth: true,
          gtm: 'new-rule',
          breadcrumb: [
            { name: 'Suspicious Codes', routeName: 'Suspicious Codes Detail' },
            { name: 'Monitoring rules', routeName: 'Monitoring rules' },
            { name: 'New rule', routeName: 'New rule' }
          ]
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../layout/Layout.vue'),
    redirect: '/user',
    children: [
      {
        path: '/user',
        name: 'User Management',
        component: () => import('@/views/private/admin/user/User.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'User Management',
          requiresAuth: true,
          gtm: 'user-management',
          breadcrumb: [{ name: 'User Management', routeName: 'User Management' }]
        }
      },
      {
        path: '/user/new',
        name: 'Create User',
        component: () => import('@/views/private/admin/user/AddNewUser.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Create User',
          requiresAuth: true,
          gtm: 'create-user',
          breadcrumb: [
            { name: 'User Management', routeName: 'User Management' },
            { name: 'Create User', routeName: 'Create User' }
          ]
        }
      },
      {
        path: '/user/:id',
        name: 'Edit User',
        component: () => import('@/views/private/admin/user/AddNewUser.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit User',
          requiresAuth: true,
          gtm: 'edit-user',
          breadcrumb: [
            { name: 'User Management', routeName: 'User Management' },
            { name: 'Edit User', routeName: 'Edit User' }
          ]
        }
      },
      {
        path: '/company',
        name: 'Company Management',
        component: () => import('@/views/private/admin/company/Company.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Company Management',
          requiresAuth: true,
          gtm: 'company-management',
          breadcrumb: [{ name: 'Company Management', routeName: 'Company Management' }]
        }
      },
      {
        path: '/company/new',
        name: 'Create Company',
        component: () => import('@/views/private/admin/company/AddNewCompany.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Create Company',
          requiresAuth: true,
          gtm: 'create-company',
          breadcrumb: [
            { name: 'Company Management', routeName: 'Company Management' },
            { name: 'Create Company', routeName: 'Create Company' }
          ]
        }
      },
      {
        path: '/company/:id',
        name: 'Edit Company',
        component: () => import('@/views/private/admin/company/AddNewCompany.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Company',
          requiresAuth: true,
          gtm: 'edit-company',
          breadcrumb: [
            { name: 'Company Management', routeName: 'Company Management' },
            { name: 'Edit Company', routeName: 'Edit Company' }
          ]
        }
      },
      {
        path: '/metadata',
        name: 'Metadata',
        component: () => import('@/views/private/admin/metadata/Metadata.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Metadata',
          requiresAuth: true,
          gtm: 'metadata',
          breadcrumb: [{ name: 'Metadata', routeName: 'Metadata' }]
        }
      },
      {
        path: '/metadata/new',
        name: 'Add New',
        component: () => import('@/views/private/admin/metadata/AddNewMetadataLayout.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Add New',
          requiresAuth: true,
          gtm: 'create-metadata',
          breadcrumb: [
            { name: 'Metadata', routeName: 'Metadata' },
            { name: 'Add New', routeName: 'Add New' }
          ]
        }
      },
      {
        path: '/metadata/:id',
        name: 'Edit Metadata',
        component: () => import('@/views/private/admin/metadata/AddNewMetadataLayout.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Metadata',
          requiresAuth: true,
          gtm: 'edit-metadata',
          breadcrumb: [
            { name: 'Metadata', routeName: 'Metadata' },
            { name: 'Edit Metadata', routeName: 'Edit Metadata' }
          ]
        }
      }
    ]
  },
  {
    path: '/brand-reporter',
    name: 'BrandReporter',
    component: () => import('../layout/Layout.vue'),
    redirect: '/brand-reporter/search',
    meta: {
      breadcrumb: [{ name: 'BrandReporter', routeName: 'BrandReporter' }]
    },
    children: [
      {
        path: '/brand-reporter/search',
        name: 'Search',
        component: () => import('@/views/private/brand-reporter/search/Search.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Search',
          requiresAuth: true,
          gtm: 'search'
        }
      },
      {
        path: '/brand-reporter/settings',
        name: 'Settings',
        component: () => import('@/views/private/brand-reporter/settings/Settings.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Settings',
          requiresAuth: true,
          gtm: 'settings',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Settings'
            },
            {
              name: 'Settings',
              routeName: 'Settings'
            }
          ]
        }
      },
      {
        path: '/brand-reporter/settings/site/:id',
        name: 'Edit Site',
        component: () => import('@/views/private/brand-reporter/settings/site/AddNewSite.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Site',
          requiresAuth: true,
          gtm: 'edit-Site',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Settings'
            },
            { name: 'Settings', routeName: 'Settings' },
            { name: 'Edit Site', routeName: 'Edit Site' }
          ]
        }
      },
      {
        path: '/brand-reporter/customer',
        name: 'Customer',
        component: () => import('@/views/private/brand-reporter/customer/Customer.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Customers',
          requiresAuth: true,
          gtm: 'customer',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Customer'
            },
            { name: 'Customer', routeName: 'Customer' }
          ]
        }
      },
      {
        path: '/brand-reporter/customer/:id',
        name: 'Edit Customer',
        component: () => import('@/views/private/brand-reporter/customer/AddNewCustomer.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Edit Customer',
          requiresAuth: true,
          gtm: 'edit-customer',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Customer'
            },
            { name: 'Customer', routeName: 'Customer' },
            { name: 'Edit Customer', routeName: 'Edit Customer' }
          ]
        }
      },
      {
        path: '/brand-reporter/container',
        name: 'Container',
        component: () => import('@/views/private/brand-reporter/container/Container.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Containers',
          requiresAuth: true,
          gtm: 'container',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Container'
            },
            { name: 'Container', routeName: 'Container' }
          ]
        }
      },
      {
        path: '/brand-reporter/container/:id',
        name: 'Container Details',
        component: () => import('@/views/private/brand-reporter/container/ContainerDetails.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Container Details',
          requiresAuth: true,
          gtm: 'container-details',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Container'
            },
            { name: 'Container', routeName: 'Container' },
            { name: 'Container Details', routeName: 'Container Details' }
          ]
        }
      },
      {
        path: '/brand-reporter/product/:id',
        name: 'Container Product Details',
        component: () => import('@/views/private/brand-reporter/search/product/ProductDetail.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Container Product Details',
          requiresAuth: true,
          gtm: 'product-details',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Container'
            },
            { name: 'Container', routeName: 'Container' },
            { name: 'Product Details', routeName: 'Container Product Details' }
          ]
        }
      },
      {
        path: '/brand-reporter/container-order',
        name: 'Container Order',
        component: () =>
          import('@/views/private/brand-reporter/container-order/ContainerOrder.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Container Orders',
          requiresAuth: true,
          gtm: 'container-order',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Customer Order'
            },
            { name: 'Container Order', routeName: 'Container Order' }
          ]
        }
      },
      {
        path: '/brand-reporter/container-order/:id',
        name: 'Container Order Detail',
        component: () =>
          import('@/views/private/brand-reporter/container-order/AddNewContainerOrder.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Container Order Details',
          requiresAuth: true,
          gtm: 'edit-container-order',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Container Order'
            },
            { name: 'Container Order', routeName: 'Container Order' },
            {
              name: 'Container Order Detail',
              routeName: 'Container Order Detail'
            }
          ]
        }
      },
      {
        path: '/brand-reporter/customer-order',
        name: 'Customer Order',
        component: () => import('@/views/private/brand-reporter/customer-order/CustomerOrder.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Customer Orders',
          requiresAuth: true,
          gtm: 'customer-order',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Customer Order'
            },
            { name: 'Customer Order', routeName: 'Customer Order' }
          ]
        }
      },
      {
        path: '/brand-reporter/customer-order/:id',
        name: 'Edit Customer Order',
        component: () =>
          import('@/views/private/brand-reporter/customer-order/EditCustomerOrder.vue'),
        beforeEnter: verifyPrivatePage,
        meta: {
          title: 'Customer Order Details',
          requiresAuth: true,
          gtm: 'edit-customer-order',
          breadcrumb: [
            {
              name: 'BrandReporter',
              routeName: 'Customer Order'
            },
            { name: 'Customer Order', routeName: 'Customer Order' },
            { name: 'Customer Order Detail', routeName: 'Edit Customer Order' }
          ]
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pagenotfound',
    component: () => import('@/views/public/404-not-found/PageNotFound.vue'),
    beforeEnter(to, from, next) {
      next();
    },
    meta: { title: 'Page not found', gtm: 'page-not-found' }
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

router.afterEach((to) => {
  const parent = to.matched.find((record) => record.meta.title);
  const parentTitle = parent ? parent.meta.title : null;
  document.title = to.meta.title || parentTitle || 'Connect';
});

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    document.title = to.meta.title as string;
    if (to.path === from.path) {
      next();
      return;
    }
    next();
  }
);

function verifyPrivatePage(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const useDecodedJwt = decodedJwt();
  const isSaaSLoggedIn = useDecodedJwt ?? null;
  const isRequireEmailVerification = useDecodedJwt?.requiresEmailVerification;
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);

  // define UTM parameters to track
  const utmParams = [
    'utm_id',
    'utm_content',
    'utm_campaign',
    'utm_medium',
    'utm_source',
    'utm_term'
  ];
  const utmQuery: Record<string, string> = {};

  // extract UTM parameters from the current URL
  utmParams.forEach((param) => {
    const value = searchParams.get(param);
    if (value) {
      utmQuery[param] = value;
    }
  });

  localStorage.setItem('fromLastRoute', from.name as string);

  // if the user is not logged in
  if (!isSaaSLoggedIn) {
    localStorage.setItem('lastVisitedRoute', to.fullPath);
    if (to.path !== '/login') {
      // redirect to login with UTM parameters
      next({
        path: '/login',
        query: { ...utmQuery }
      });
    } else {
      next();
    }
  } else if (isRequireEmailVerification) {
    if (to.path !== '/signup') {
      next({ path: '/signup', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else if (searchParams.get('pricing') === 'true' && to.path !== '/account/subscription') {
    next({ path: '/account/subscription' });
  } else {
    next();
  }
}
function verifyPublicPage(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const useDecodedJwt = decodedJwt();
  const isSaaSLoggedIn = useDecodedJwt ?? null;
  const isRequireEmailVerification = useDecodedJwt?.requiresEmailVerification ?? null;
  const isCanvaUrl = new URL(window.location.href)?.pathname === '/login/canva';
  if (isSaaSLoggedIn && !isRequireEmailVerification) {
    if (isCanvaUrl) {
      next({
        path: '/login/canva/link'
      });
    } else {
      next({
        path: from.path
      });
    }
  } else {
    next();
  }
}

export default router;
