/* eslint-disable import/no-extraneous-dependencies */
import '@/helpers/vuetify-css';
import { PureAbility } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';
import { GrowthBook } from '@growthbook/growthbook';
import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import { createHead } from '@unhead/vue';
import { MotionPlugin } from '@vueuse/motion';
import 'driver.js/dist/driver.css';
import type { InjectionKey } from 'vue';
import { createApp, reactive } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueGoogleCharts from 'vue-google-charts';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import 'vue-svg-inline-plugin/src/polyfills';
import App from './app/App.vue';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import router from './router';
import { store } from './store';

// Create a reactive instance of GrowthBook
export const gbInstance = reactive(
  new GrowthBook({
    clientKey: import.meta.env.VITE_APP_GROWTHBOOK_CLIENT_KEY,
    attributes: {}, // Empty initially, will be updated later
    enableDevMode: true // Optional: Enable the Visual Editor and dev tools
  })
);

// Share the provider type with other components
export const gbKey = Symbol('gb') as InjectionKey<typeof gbInstance | null>;

// Initialize GrowthBook with streaming enabled for real-time updates
const initializeGrowthBook = async () => {
  try {
    await gbInstance.init({ streaming: true });

    // Subscribe to store changes to update attributes when profile is loaded
    store.subscribe((mutation, state) => {
      if (mutation.type === 'profile/SELECTED_PROFILE') {
        try {
          const profileState = state?.profile;
          if (profileState?.selected_profile) {
            const profile = profileState.selected_profile;
            gbInstance.setAttributes({
              accountId: profile?.accountId,
              userId: profile?.id
            });
          }
        } catch (error) {
          console.error('Error updating GrowthBook attributes:', error);
        }
      }
    });
    return gbInstance;
  } catch (e) {
    console.error('Error initializing GrowthBook:', e);
    return null;
  }
};

const ability = new PureAbility();
const head = createHead();
const isProduction = import.meta.env.MODE === 'production';

const gtmId = isProduction ? 'GTM-KT5L7XKC' : 'GTM-WT9H7XF5';
// tanstack query client config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: 30 * 60 * 1000, // 30 minutes
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true
    }
  }
});

// Check if we already have profile data in the store during init
const checkExistingProfileData = () => {
  try {
    const profileState = store.state?.profile;
    if (profileState?.selected_profile) {
      const profile = profileState.selected_profile;
      gbInstance.setAttributes({
        accountId: profile?.accountId,
        userId: profile?.id
      });
    }
  } catch (error) {
    console.error('Error checking for existing profile data:', error);
  }
};

// eslint-disable-next-line @typescript-eslint/no-shadow
initializeGrowthBook().then((gbInstance) => {
  checkExistingProfileData();
  const app = createApp(App);
  app.use(head);
  app.use(VueDOMPurifyHTML, {
    hooks: {
      beforeSanitizeAttributes: (node) => {
        if (node.tagName === 'A') {
          if (node.hasAttribute('target')) {
            node.setAttribute('data-temp-href-target', node.getAttribute('target'));
          }
        }
      },
      afterSanitizeAttributes: (node) => {
        if (node.tagName === 'A' && node.hasAttribute('data-temp-href-target')) {
          node.setAttribute('target', node.getAttribute('data-temp-href-target'));
          node.removeAttribute('data-temp-href-target');
          if (node.getAttribute('target') === '_blank') {
            node.setAttribute('rel', 'noopener noreferrer');
          }
        }
      }
    }
  });
  app.use(VueGoogleCharts);
  app.use(router);
  app.use(store);
  app.use(vuetify);
  app.use(abilitiesPlugin, ability);
  app.use(VueSvgInlinePlugin);
  app.use(
    createGtm({
      id: gtmId,
      vueRouter: router
    })
  );
  app.use(VueQueryPlugin, { queryClient });
  app.use(MotionPlugin);
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 50% of the transactions
    tracePropagationTargets: [
      /^https:\/\/dashboard.staging.connectqr\.ai\/api/,
      /^https:\/\/dashboard.connectqr\.ai\/api/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  // Provide the GrowthBook instance
  app.provide(gbKey, gbInstance);
  app.mount('#root');
});
