/* eslint-disable import/order */
import { ERuleTypes } from '@/modules/brand-protection/brand-protection.enum';
import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { BrandProtectionState } from './types';

const rules = {
  id: '',
  name: '',
  companyId: '',
  ruleId: '',
  isActive: false,
  ruleType: ERuleTypes.SCANS_OVER_TIME,
  type: ERuleTypes.SCANS_OVER_TIME,
  config: {
    threshold: 0,
    product: [],
    scanRange: {
      unit: 'days',
      amount: 0
    }
  },
  createdAt: new Date(),
  updatedAt: new Date()
};
const state: BrandProtectionState = {
  brand_protection_rules: [rules],
  selected_brand_protection_rule: rules,
  scans_over_time_report: [],
  scan_over_time_locations: null,
  scans_over_time_countries: null,
  paginated_products: [],
  selected_alert: null,
  product_country_scans_report: null,
  product_country_scans_map: null
};

// eslint-disable-next-line import/prefer-default-export
export const brandprotection: Module<BrandProtectionState, IRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
