import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { ITrackTraceState } from './types';

const state: ITrackTraceState = {
  selected_container: null,
  selected_container_description: null,
  container_unlock_request_id: null,
  update_container_changes: null,
  restore_container_items: [],
  update_item_in_container: null,
  paginated_container: null,
  selected_bulk_container: null,
  transfer_container_list: [],
  all_sorted_container_type: null,
  selected_container_type: null,
  update_selected_container_type: null,
  paginated_customer: null,
  selected_customer: null,
  update_selected_customer: null,
  paginated_site: null,
  selected_site: null,
  update_selected_site: null,
  paginated_customer_order: null,
  selected_customer_order: null,
  update_customer_order: null,
  validated_container: null,
  update_selected_customer_order_item: null,
  all_customer_order_items: null,
  customer_order_restore_list: null,
  customer_order_actions: null,
  selected_customer_order_action: null,
  paginated_container_order: null,
  search_container_and_product: null,
  container_history: null,
  container_product_details: null,
  update_container_transaction_content: null,
  update_transact_item_in_container: null,
  container_actions: null
};

// eslint-disable-next-line import/prefer-default-export
export const tracktrace: Module<ITrackTraceState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
