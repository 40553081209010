import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { IPageThemeState } from './types';

const state: IPageThemeState = {
  paginated_themes: null,
  selected_theme: null,
  update_selected_theme: null,
  update_selected_theme_for_lookup: null,
  newly_created_theme_for_edit_qr: null
};

// eslint-disable-next-line import/prefer-default-export
export const pageTheme: Module<IPageThemeState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
