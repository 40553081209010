import { ERootMutations } from '@/modules/common/common.enum';
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import { IErrorResponse } from '@/modules/error/error.interface';
import { TTechBatchAction } from '@/modules/tech-batch/tech-batch.interface';
import {
  IContainerDetails,
  IContainerWithContent,
  ICreateContainerApi
} from '@/modules/tracktrace/container.interface';
import { ICustomerOrderActionsResponse } from '@/modules/tracktrace/order-actions';
import {
  ICustomerOrderItemResponse,
  IOrderContainerItem
} from '@/modules/tracktrace/order.interface';
import {
  IContainerActionsByTypePayload,
  IContainerAvailableActionsResponse,
  ICreateContainerTypeModified,
  ICreateCustomerOrderItem,
  ICreateSite,
  IValidateContainer
} from '@/modules/tracktrace/tracktrace.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import {
  ISearchableContainerOrder,
  ISearchableCustomer,
  ISearchableCustomerOrder,
  ISearchableSite
} from '@connect-saas/modules/search';
import {
  IContainer,
  IContainerContentItem,
  IContainerContentTransactionItem,
  IContainerOrder,
  IContainerOrderDownloadResponse,
  IContainerProductDetails,
  IContainerProductHistoryApiResponse,
  IContainerProductHistoryItem,
  IContainerSearchItem,
  IContainerTransactionContent,
  IContainerTransactionContentItem,
  IContainerType,
  ICreateContainerOrder,
  ICreateContainerType,
  ICreateCustomer,
  ICreateCustomerOrder,
  ICustomer,
  ICustomerOrder,
  ICustomerOrderItem,
  ISearchableContainer,
  ISite,
  IUpdateContainerApi
} from '@connect-saas/types';
import { ActionContext, ActionTree } from 'vuex';
import { ITrackTraceState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<ITrackTraceState, IRootState> = {
  CREATE_CONTAINER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: ICreateContainerApi
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .createContainer(payload)
        .then((response: ResourceResponse<IContainer>) => {
          // const getContainerPayload = {
          //   page: 1,
          //   itemsPerPage: 15,
          //   sort: { field: null, direction: null }
          // };
          commit('SELECTED_CONTAINER', response.resource);
          // dispatch('GET_PAGINATED_CONTAINER', getContainerPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_CONTAINER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: { id: string; body: IUpdateContainerApi }
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .updateContainer(payload.id, payload.body)
        .then((response: ResourceResponse<IContainer>) => {
          // const getContainerPayload = {
          //   page: 1,
          //   itemsPerPage: 15,
          //   sort: { field: null, direction: null }
          // };
          commit('SELECTED_CONTAINER', response.resource);
          // dispatch('GET_PAGINATED_CONTAINER', getContainerPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONTAINER_BY_ID(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getContainerById(id)
        .then((response: ResourceResponse<IContainerWithContent>) => {
          commit('SELECTED_CONTAINER', response.resource);
          commit('SELECTED_CONTAINER_DESCRIPTION', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_CONTAINER(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .deleteContainerById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  REMOVE_ITEM_FROM_CONTAINER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: { containerId: string; typeAndId: IContainerContentItem }
  ): Promise<ResourceResponse<void>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .removeItemFromContainer(payload.containerId, payload.typeAndId)
        .then((response: ResourceResponse<void>) => {
          commit('UPDATE_ITEM_IN_CONTAINER');
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONTAINER_TRANSACTION_CONTENT(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    containerId: string
  ): Promise<IContainerTransactionContent> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .transactionContent(containerId)
        .then((response: IContainerTransactionContent) => {
          commit('UPDATE_CONTAINER_TRANSACTION_CONTENT', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  TRANSACT_ITEM_IN_CONTAINER(
    { commit }: ActionContext<ITrackTraceState, IRootState>,
    payload: { containerId: string; code: string }
  ): Promise<{
    change: IContainerContentTransactionItem;
    content: IContainerTransactionContentItem[];
  }> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .transactItem(payload.containerId, payload.code)
        .then(
          (
            response: ResourceResponse<{
              change: IContainerContentTransactionItem;
              content: IContainerTransactionContentItem[];
            }>
          ) => {
            commit('UPDATE_TRANSACT_ITEM_IN_CONTAINER', response.resource);
            commit('UPDATE_CONTAINER_TRANSACTION_CONTENT', response.resource);
            resolve(response.resource);
          }
        )
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            const error: IErrorResponse = (errorResponse as any)?.response?.data ?? errorResponse;
            const errorObj: IErrorResponse = {
              code: error.code,
              status: error.status,
              message: error.message
            };
            const errorMessage = errorObj.message
              ? errorObj.message.charAt(0).toUpperCase() + errorObj.message.slice(1)
              : '';
            commit(ERootMutations.SET_FRIENDLY_ERR_MSG, errorMessage, { root: true });
            reject(error);
          }
        });
    });
  },
  LOCK_UNLOCK_CONTAINER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: { containerId: string; type: string }
  ): Promise<ResourceResponse<IContainer>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .lockOrUnlockContainer(payload.containerId, payload.type)
        .then((response: ResourceResponse<IContainer>) => {
          commit('SELECTED_CONTAINER', response.resource);
          commit('SELECTED_CONTAINER_DESCRIPTION', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGINATED_CONTAINER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableContainer>>> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getTrackTrace()
        .getPaginatedContainers(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableContainer>>) => {
          if (!payload.isScan) {
            commit('UPDATE_PAGINATED_CONTAINER', response);
          }
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CHECKIN_CHECKOUT_CONTAINER(
    { commit }: ActionContext<ITrackTraceState, IRootState>,
    payload: { containerId: string; siteId: string; type: string }
  ): Promise<ResourceResponse<IContainer>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .checkinOrCheckoutContainer(payload.containerId, payload.siteId, payload.type)
        .then((response: ResourceResponse<IContainer>) => {
          commit('TRANSFER_CONTAINER_LIST', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            const error: IErrorResponse = (errorResponse as any)?.response?.data ?? errorResponse;
            const errorObj: IErrorResponse = {
              code: error.code,
              status: error.status,
              message: error.message
            };
            const errorMessage = errorObj.message
              ? errorObj.message.charAt(0).toUpperCase() + errorObj.message.slice(1)
              : '';
            commit(ERootMutations.SET_FRIENDLY_ERR_MSG, errorMessage, { root: true });
            reject(error);
          }
        });
    });
  },
  CREATE_CONTAINER_TYPE(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: ICreateContainerType
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .createContainerType(payload)
        .then((response: ResourceResponse<IContainerType>) => {
          commit('SELECTED_CONTAINER_TYPE', response.resource);
          dispatch('GET_ALL_SORTED_CONTAINER_TYPE');
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_ALL_SORTED_CONTAINER_TYPE({
    commit,
    dispatch
  }: ActionContext<ITrackTraceState, IRootState>): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getAllSortedContainerType()
        .then((response: ResourceResponse<IContainerType[]>) => {
          commit('UPDATE_ALL_SORTED_CONTAINER_TYPE', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_CONTAINER_TYPE(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: ICreateContainerTypeModified
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .updateContainerType(payload.id, payload)
        .then((response: ResourceResponse<IContainerType>) => {
          commit('UPDATE_SELECTED_CONTAINER_TYPE', response.resource);
          dispatch('GET_ALL_SORTED_CONTAINER_TYPE');
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONTAINER_TYPE_BY_ID(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getContainerTypeById(id)
        .then((response: ResourceResponse<IContainerType | null>) => {
          commit('SELECTED_CONTAINER_TYPE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGINATED_CUSTOMER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getTrackTrace()
        .getPaginatedCustomers(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableCustomer>>) => {
          commit('UPDATE_PAGINATED_CUSTOMER', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_CUSTOMER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: ICreateCustomer
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .createCustomer(payload)
        .then((response: ResourceResponse<ICustomer>) => {
          commit('UPDATE_SELECTED_CUSTOMER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_CUSTOMER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: { id: string; body: Partial<ICreateCustomer> }
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .updateCustomer(payload.id, payload.body)
        .then((response: ResourceResponse<ICustomer>) => {
          commit('UPDATE_SELECTED_CUSTOMER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CUSTOMER_BY_ID(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getCustomerById(id)
        .then((response: ResourceResponse<ICustomer | null>) => {
          commit('SELECTED_CUSTOMER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_CUSTOMER(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .deleteCustomerById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGINATED_SITE(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getTrackTrace()
        .getPaginatedSites(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableSite>>) => {
          commit('UPDATE_PAGINATED_SITE', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_SITE(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: ICreateSite
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .createSite(payload)
        .then((response: ResourceResponse<ICustomer>) => {
          commit('UPDATE_SELECTED_SITE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_SITE(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: { id: string; body: Partial<ICreateSite> }
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .updateSite(payload.id, payload.body)
        .then((response: ResourceResponse<ISite>) => {
          commit('UPDATE_SELECTED_SITE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SITE_BY_ID(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getSiteById(id)
        .then((response: ResourceResponse<ICustomer | null>) => {
          commit('SELECTED_SITE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_SITE(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .deleteSiteById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGINATED_CUSTOMER_ORDER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getTrackTrace()
        .getPaginatedCustomerOrder(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableCustomerOrder>>) => {
          commit('UPDATE_PAGINATED_CUSTOMER_ORDER', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_CUSTOMER_ORDER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: ICreateCustomerOrder
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .createCustomerOrder(payload)
        .then((response: ResourceResponse<ICustomerOrder>) => {
          commit('UPDATE_CUSTOMER_ORDER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CUSTOMER_ORDER_BY_ID(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getCustomerOrderById(id)
        .then((response: ResourceResponse<ICustomerOrder | null>) => {
          commit('SELECTED_CUSTOMER_ORDER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  VALIDATE_CONTAINER(
    { commit }: ActionContext<ITrackTraceState, IRootState>,
    payload: IValidateContainer
  ): Promise<ResourceResponse<IContainer>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .validateContainer(payload.orderId, payload.containerCode)
        .then((response: ResourceResponse<IContainer>) => {
          commit('VALIDATED_CONTAINER', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            const error: IErrorResponse = (errorResponse as any)?.response?.data ?? errorResponse;
            const errorObj: IErrorResponse = {
              code: error.code,
              status: error.status,
              message: error.message
            };
            const errorMessage = errorObj.message
              ? errorObj.message.charAt(0).toUpperCase() + errorObj.message.slice(1)
              : '';
            commit(ERootMutations.SET_FRIENDLY_ERR_MSG, errorMessage, { root: true });
            reject(error);
          }
        });
    });
  },
  ADD_CONTAINER_ITEM_TO_CUSTOMER_ORDER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: Partial<ICreateCustomerOrderItem>
  ): Promise<ResourceResponse<ICustomerOrderItem>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .addContainerItemToCustomerOrder(payload)
        .then((response: ResourceResponse<ICustomerOrderItem>) => {
          commit('UPDATE_SELECTED_CUSTOMER_ORDER_ITEM', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_CUSTOMER_ORDER_ITEM(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: any
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .deleteCustomerOrderItemById(payload.customerOrderId, payload.customerOrderItemId)
        .then((response) => {
          commit('UPDATE_SELECTED_CUSTOMER_ORDER_ITEM', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_ALL_CUSTOMER_ORDER_ITEMS(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: string
  ): Promise<ResourceResponse<ICustomerOrderItemResponse>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getAllCustomerOrderItems(payload)
        .then((response) => {
          commit('UPDATE_ALL_CUSTOMER_ORDER_ITEMS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CUSTOMER_ORDER_ACTIONS(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: string
  ): Promise<ResourceResponse<ICustomerOrderActionsResponse>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getCustomerOrderActions(payload)
        .then((response) => {
          commit('UPDATE_CUSTOMER_ORDER_ACTIONS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  PERFORM_CUSTOMER_ORDER_ACTION(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload
  ): Promise<ResourceResponse<ICustomerOrder>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .performCustomerOrderAction(payload.customerOrderId, payload.request)
        .then((response: ResourceResponse<ICustomerOrder>) => {
          commit('SELECTED_CUSTOMER_ORDER_ACTION', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  ACTIVATE_SELECTED_CUSTOMER_ORDER_ITEM(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload
  ): Promise<ResourceResponse<IOrderContainerItem>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .activateCustomerOrderItemById(
          payload.customerOrderId,
          payload.customerOrderItemId,
          payload.action
        )
        .then((response: ResourceResponse<IOrderContainerItem>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGINATED_CONTAINER_ORDER(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableContainerOrder>>> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getTrackTrace()
        .getPaginatedContainerOrders(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableContainerOrder>>) => {
          commit('UPDATE_PAGINATED_CONTAINER_ORDER', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_CONTAINER_ORDER(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: ICreateContainerOrder
  ): Promise<ResourceResponse<IContainerOrder>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .createContainerOrder(payload)
        .then((response: ResourceResponse<IContainerOrder>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONTAINER_ORDER_BY_ID(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<ResourceResponse<IContainerOrder>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getContainerOrderById(id)
        .then((response: ResourceResponse<IContainerOrder>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UNLOCK_CONTAINER_REQUEST(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    id: string
  ): Promise<ResourceResponse<IContainerDetails>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .unlockContainerRequest(id)
        .then((response: ResourceResponse<IContainerDetails>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONTAINER_ORDER_DOWNLOAD_URL(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: { id: string; action: TTechBatchAction }
  ): Promise<IContainerOrderDownloadResponse> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getContainerOrderDownloadUrlByAction(payload.id, payload.action)
        .then((response: IContainerOrderDownloadResponse) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  SEARCH_CONTAINER_AND_PRODUCT(
    { commit }: ActionContext<ITrackTraceState, IRootState>,
    payload: string
  ): Promise<IContainerSearchItem> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .searchContainerAndProduct(payload)
        .then((response: IContainerSearchItem) => {
          commit('UPDATE_SEARCH_CONTAINER_AND_PRODUCT', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            const error: IErrorResponse = (errorResponse as any)?.response?.data ?? errorResponse;
            reject(error);
          }
        });
    });
  },
  GET_CONTAINER_HISTORY(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: string
  ): Promise<IContainerProductHistoryItem[]> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getContainerHistory(payload)
        .then((response: IContainerProductHistoryApiResponse) => {
          commit('UPDATE_CONTAINER_HISTORY', response?.data);
          resolve(response?.data);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONTAINER_PROUDCT_DETAILS(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: string
  ): Promise<IContainerProductDetails> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getContainerProductDetails(payload)
        .then((response: IContainerProductDetails) => {
          commit('UPDATE_CONTAINER_PRODUCT_DETAILS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONTAINER_ACTIONS_BY_ID(
    { commit, dispatch }: ActionContext<ITrackTraceState, IRootState>,
    containerId: string
  ): Promise<IContainerAvailableActionsResponse> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .getContainerActionsById(containerId)
        .then((response: IContainerAvailableActionsResponse) => {
          commit('UPDATE_CONTAINER_ACTIONS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  PERFORM_CONTAINER_ACTIONS_BY_TYPE(
    { dispatch }: ActionContext<ITrackTraceState, IRootState>,
    payload: IContainerActionsByTypePayload
  ): Promise<ResourceResponse<IContainer>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTrackTrace()
        .performContainerActionsByType(payload)
        .then((response: ResourceResponse<IContainer>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};
