import { ISearchablePageTheme } from '@connect-saas/modules/search';
import { IPaginatedData } from '@connect-saas/util/common';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';
import { IPageTheme, IPageThemeCreate } from '@connect-saas/types';

export default class PageTheme {
  private readonly client: ClientInterface;

  private readonly resource = 'page-theme';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  createTheme(body: IPageThemeCreate): Promise<ResourceResponse<IPageTheme>> {
    return this.client.create<IPageThemeCreate, IPageTheme>(this.resource, body);
  }

  updateTheme(id: string, body: Partial<IPageThemeCreate>): Promise<ResourceResponse<IPageTheme>> {
    return this.client.patch(this.resource, body, id);
  }

  deleteTheme(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }

  getPaginatedThemes(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchablePageTheme>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<ISearchablePageTheme>>(builder);
  }

  getPageThemeById(id: string): Promise<ResourceResponse<IPageTheme | null>> {
    return this.client.getById(this.resource, id);
  }

  duplicateTheme(id: string): Promise<ResourceResponse<ISearchablePageTheme>> {
    return this.client.post(`${this.resource}/${id}/duplicate`);
  }
}
