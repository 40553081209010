import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { IPageState } from './types';

const state: IPageState = {
  paginated_pages: null,
  selected_page: null,
  update_selected_page: null,
  update_selected_page_for_lookup: null,
  newly_created_page_for_edit_qr: null,
  page_changing: false
};

// eslint-disable-next-line import/prefer-default-export
export const page: Module<IPageState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
