<script setup lang="ts">
import useWebSocket from '@/composables/useWebSocket';
import { useFavicon } from '@vueuse/core';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
store.commit('GET_HOST_NAME');

const isEcocanHost = computed(() => store.state.is_ecocan_host);
const changeFavicon = () => {
  const icon = isEcocanHost.value ? 'ecocan.ico' : 'favicon.ico';
  useFavicon(icon, { baseUrl: '/' });
};

const { needRefresh, updateServiceWorker } = useRegisterSW();
const updateMessage = ref('Connect updated! Refresh now.');

let websocketUrl = import.meta.env.VITE_APP_WEBSOCKET_URL;

const currentUrl = new URL(window.location.href);
if (currentUrl.host === 'dashboard.staging.connectqr.ai') {
  websocketUrl = 'wss://dashboard.staging.connectqr.ai/wss';
}

useWebSocket(websocketUrl, store);

onMounted(() => {
  changeFavicon();
});
</script>

<template>
  <div class="application theme--light" id="root">
    <v-app>
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <component :is="Component" />
        </Transition>
      </router-view>
      <v-snackbar timeout="-1" location="top right" color="purple-darken-3" v-model="needRefresh">
        <span>{{ updateMessage }}</span>
        <template #actions>
          <v-btn
            class="text-capitalize"
            color="sheen-green-darken-1"
            prepend-icon="mdi-refresh"
            variant="flat"
            @click="updateServiceWorker"
            >Refresh</v-btn
          >
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<style lang="scss">
.v-application a {
  color: rgb(var(--v-theme-primary));
}
</style>
