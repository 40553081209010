import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { ISearchablePageTheme } from '@connect-saas/modules/search';
import { IPageTheme, IPageThemeCreate } from '@connect-saas/types';
import { IPaginatedData } from '@connect-saas/util/common';
import { ActionContext, ActionTree } from 'vuex';
import { IPageThemeState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IPageThemeState, IRootState> = {
  GET_PAGINATED_THEMES({ commit, dispatch }: ActionContext<IPageThemeState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      builder.setSearch(payload.search ?? '');
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      ApiFactory.getPageTheme()
        .getPaginatedThemes(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchablePageTheme>>) => {
          commit('UPDATE_PAGINATED_THEMES', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_THEME_BY_ID({ commit, dispatch }: ActionContext<IPageThemeState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getPageTheme()
        .getPageThemeById(id)
        .then((response: ResourceResponse<IPageTheme | null>) => {
          commit('SELECTED_THEME', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_THEME(
    { commit, dispatch }: ActionContext<IPageThemeState, IRootState>,
    payload: IPageThemeCreate
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getPageTheme()
        .createTheme(payload)
        .then((response: ResourceResponse<IPageTheme>) => {
          commit('UPDATE_SELECTED_THEME', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_SELECTED_THEME(
    { commit, dispatch }: ActionContext<IPageThemeState, IRootState>,
    payload: { id: string; body: Partial<IPageThemeCreate> }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getPageTheme()
        .updateTheme(payload.id, payload.body)
        .then((response: ResourceResponse<IPageTheme>) => {
          commit('UPDATE_SELECTED_THEME', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};
