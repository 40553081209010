import {
  IAlertFilters,
  IAlertTimeline,
  IProductCountryScansMapItem,
  IProductCountryScansReportItem,
  IRule,
  IScansOverTimeAlert,
  ISuspiciousCodeList,
  ISuspiciousProtectCodeReportAlerts,
  ISuspiciousProtectCodeReportCountries,
  ISuspiciousProtectCodeReportLocations,
  ISuspiciousProtectCodeReportAlertMute
} from '@/modules/brand-protection/brand-protection.interface';
import { IScansList } from '@/modules/common/location.interface';
import { IProtectCodeDataFromAlert, ISuspiciousProtectCodeAlertReport } from '@connect-saas/types';
import ClientInterface from './common/ClientInterface';
import GeohashScansBuilder from './common/GeohashScansBuilder';
import GeohashScansResponse from './common/GeohashScansResponse';
import PaginatedResponse from './common/PaginatedResponse';
import ResourceResponse from './common/ResourceResponse';

export default class BrandProtection {
  private readonly client: ClientInterface;

  private readonly RESOURCE_V1: string = 'brand-protection';

  private readonly RESOURCE_V2: string = 'v2/brand-protection';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAlertLocations(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<IScansList>> {
    builder.setResource(`${this.RESOURCE_V1}/alert-locations`);
    return this.client.getScansByGeohash<IScansList>(builder);
  }

  getAlertTimeline(payload: IAlertFilters): Promise<IAlertTimeline> {
    return this.client.get<IAlertTimeline>(
      `${this.RESOURCE_V1}/alert-timeline?from=${payload.start}&to=${payload.end}&granularity=${payload.granularity}`
    );
  }

  getPaginatedSuspiciousCodes(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<PaginatedResponse<ISuspiciousCodeList>> {
    builder.setResource(`${this.RESOURCE_V1}/suspicious-codes`);
    return this.client.getPaginated<ISuspiciousCodeList>(builder);
  }

  getBrandProtectionRules(): Promise<ResourceResponse<IRule[]>> {
    return this.client.get(`${this.RESOURCE_V1}/rule`);
  }

  getBrandProtectionRuleById(id: string | number): Promise<ResourceResponse<IRule>> {
    return this.client.getById(`${this.RESOURCE_V1}/rule`, id);
  }

  getScansOverTimeReport(): Promise<ISuspiciousProtectCodeReportAlerts> {
    return this.client.get(`${this.RESOURCE_V2}/suspicious-protectcode/report/alerts`);
  }

  getScansOverTimeLocations(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<ISuspiciousProtectCodeReportLocations>> {
    builder.setResource(`${this.RESOURCE_V2}/suspicious-protectcode/report/locations`);
    return this.client.getScansByGeohash<ISuspiciousProtectCodeReportLocations>(builder);
  }

  getScansOverTimeCountries(): Promise<ResourceResponse<ISuspiciousProtectCodeReportCountries>> {
    return this.client.get(`${this.RESOURCE_V2}/suspicious-protectcode/report/countries`);
  }

  getProductCountryScansReport(): Promise<IProductCountryScansReportItem[]> {
    return this.client.get(`${this.RESOURCE_V1}/report/product-country-scans`);
  }

  getProductCountryScansMap(): Promise<IProductCountryScansMapItem[]> {
    return this.client.get(`${this.RESOURCE_V1}/report/product-country-scans/map`);
  }

  createAlertRule(alert: IRule): Promise<ResourceResponse<IRule>> {
    return this.client.create<IRule, IRule>(`${this.RESOURCE_V1}/rule`, alert);
  }

  updateAlertRule(id: string | number, body: IRule): Promise<ResourceResponse<IRule>> {
    return this.client.update<IRule, IRule>(`${this.RESOURCE_V1}/rule`, id, body);
  }

  deleteAlertRule(id: string | number): Promise<ResourceResponse<IRule>> {
    return this.client.delete(`${this.RESOURCE_V1}/rule`, id);
  }

  muteAlertRule(
    id: string,
    payload: ISuspiciousProtectCodeReportAlertMute
  ): Promise<ResourceResponse<void>> {
    return this.client.post(`${this.RESOURCE_V2}/suspicious-protectcode/alert/${id}/mute`, payload);
  }

  getAlertDetailsByRuleId(id: string | number): Promise<ResourceResponse<IScansOverTimeAlert>> {
    return this.client.getById(`${this.RESOURCE_V2}/alert/scans-over-time`, id);
  }

  getAlertProtectCodeByRuleId(id: string | number): Promise<IProtectCodeDataFromAlert> {
    return this.client.get(`${this.RESOURCE_V2}/alert/scans-over-time/${id}/code`);
  }

  getAlertReportByid(id: string): Promise<ISuspiciousProtectCodeAlertReport> {
    return this.client.get(`${this.RESOURCE_V2}/suspicious-protectcode/alert/${id}/report`);
  }

  suspendProtectcodeByAlert(alertId: string): Promise<ResourceResponse<void>> {
    return this.client.post(
      `${this.RESOURCE_V2}/suspicious-protectcode/alert/${alertId}/suspend-protectcode`
    );
  }
}
