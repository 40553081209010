import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators } from '@/modules/common/pagination.interface';
import {
  ICreateFolderParams,
  IMediaFile,
  IMediaFolder,
  IMediaSearchItem,
  IMediaUpload,
  IMediaUploadRequest
} from '@/modules/media-library/media.interface';
import { TDeleteMedia } from '@/modules/media-library/media.type';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IResPresignedUrlMediaFile } from '@connect-saas/types';
import axios from 'axios';
import { ActionContext, ActionTree } from 'vuex';
import { IMediaState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IMediaState, IRootState> = {
  GET_UPLOADED_PRESIGNED_URL(
    { commit, dispatch }: ActionContext<IMediaState, IRootState>,
    payload: IMediaUploadRequest
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .uploadToGetPresignedUrl(payload)
        .then((response: ResourceResponse<IMediaUpload>) => {
          commit('UPLOADED_MEDIA', response);
          const newMediaTemp = {
            ...response.resource,
            createdAt: new Date(),
            updatedAt: new Date()
          };
          commit('ADD_NEW_MEDIA', newMediaTemp);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  UPLOAD_MEDIA_TO_S3({ dispatch }: ActionContext<IMediaState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          'Content-Type': payload.type
        }
      };
      axios
        .put(payload.url, payload.body, options)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_MEDIA_FILE_BY_ID({ dispatch }: ActionContext<IMediaState, IRootState>, payload: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .getFileById(payload)
        .then((response: ResourceResponse<IMediaFile | null>) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DOWNLOAD_MEDIA_FILE_BY_PATH(
    { dispatch }: ActionContext<IMediaState, IRootState>,
    payload: { path: string }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .downloadMediaByPath(payload)
        .then((response: ResourceResponse<IResPresignedUrlMediaFile>) => {
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  UPDATE_SELECTED_FILE({ commit, dispatch }: ActionContext<IMediaState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .updateFile(payload.id, payload.body)
        .then((response: ResourceResponse<IMediaFolder>) => {
          commit('UPDATE_FILE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_MEDIA_BY_ID(
    { commit, dispatch }: ActionContext<IMediaState, IRootState>,
    payload: TDeleteMedia
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .deleteMediaById(payload)
        .then((response: void) => {
          commit('REMOVE_MEDIA_FILE', payload.id);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_FOLDER(
    { commit, dispatch }: ActionContext<IMediaState, IRootState>,
    payload: ICreateFolderParams
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .createFolder(payload)
        .then((response: ResourceResponse<IMediaFolder>) => {
          commit('UPDATE_FOLDER', response.resource);
          const newMediaTemp = {
            ...response.resource,
            createdAt: new Date(),
            updatedAt: new Date()
          };
          commit('ADD_NEW_MEDIA', newMediaTemp);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_FOLDER_BY_ID({ dispatch }: ActionContext<IMediaState, IRootState>, payload: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .getFolderById(payload)
        .then((response: ResourceResponse<IMediaFolder | null>) => {
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  UPDATE_SELECTED_FOLDER({ commit, dispatch }: ActionContext<IMediaState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMedia()
        .updateFolder(payload.id, payload.body)
        .then((response: ResourceResponse<IMediaFolder>) => {
          commit('UPDATE_FOLDER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  SEARCH_MEDIA_FILE_AND_FOLDER(
    { commit, dispatch }: ActionContext<IMediaState, IRootState>,
    payload
  ) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');

      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.input);
        });
      }
      ApiFactory.getMedia()
        .searchMedia(builder)
        .then((response: PaginatedResponse<IMediaSearchItem>) => {
          commit('UPDATE_SEARCH_MEDIA_FILE_AND_FOLDER', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ALL_FOLDERS({ commit, dispatch }: ActionContext<IMediaState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.input);
        });
      }
      ApiFactory.getMedia()
        .searchMedia(builder)
        .then((response: PaginatedResponse<IMediaSearchItem>) => {
          commit('CLEAR_NEW_ADDED_MEDIA');
          commit('UPDATE_MEDIA_FOLDER', response.resources);
          resolve(response.resources);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};
