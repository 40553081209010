// eslint-disable-next-line import/order
import {
  IRule,
  ISuspiciousProtectCodeReportAlert,
  ISuspiciousProtectCodeReportCountries,
  ISuspiciousProtectCodeReportLocations
} from '@/modules/brand-protection/brand-protection.interface';
import { IRootState } from '@/modules/common/common.interface';
import { GetterTree } from 'vuex';
import { BrandProtectionState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<BrandProtectionState, IRootState> = {
  brand_protection_rules(state): IRule[] {
    return state.brand_protection_rules;
  },
  scans_over_time_report(state): ISuspiciousProtectCodeReportAlert[] {
    return state.scans_over_time_report;
  },
  scan_over_time_locations(state): ISuspiciousProtectCodeReportLocations {
    return state.scan_over_time_locations;
  },
  scans_over_time_countries(state): ISuspiciousProtectCodeReportCountries {
    return state.scans_over_time_countries;
  }
};
