import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
// eslint-disable-next-line import/order
import { IAnalyticsScanStat } from '@/modules/analytics/technology/technology.interface';
import { IAnalyticsScanMetrics, IScanLocationCount } from '@connect-saas/types';
import { ActionTree } from 'vuex';
import { AnalyticsState } from './types';

const ApiFactory = new Api();
const paginationBuilder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<AnalyticsState, IRootState> = {
  GET_TECH_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getAlertProtectCodeByRuleId(payload.paramsId)
        .then((response) => {
          commit('SELECTED_TECH', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_PRODUCT_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getProductById(payload.id)
        .then((response) => {
          commit('SELECTED_PRODUCT', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_CAMPAIGN_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getCampaignById(payload.id)
        .then((response) => {
          commit('SELECTED_CAMPAIGN', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_DIMENSIONS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanDimensions(payload)
        .then((response: { dimensions: IAnalyticsScanStat }) => {
          commit('UPDATE_ANALYTICS_SCAN_DIMENSIONS', response.dimensions);
          resolve(response.dimensions);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_LOCATIONS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getScannedAtByLocation(payload)
        .then((response: { locations: IScanLocationCount[] }) => {
          commit('UPDATE_ANALYTICS_SCAN_LOCATIONS', response.locations);
          resolve(response.locations);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCANS_TIME_SERIES({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanTimeSeries(payload)
        .then((response) => {
          commit('UPDATE_ANALYTICS_SCANS_TIMELINE', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCANS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      paginationBuilder.setFromDate(payload.from);
      paginationBuilder.setToDate(payload.to);
      paginationBuilder.setPage(payload.page);
      paginationBuilder.setPageSize(payload.itemsPerPage);
      paginationBuilder.clearFilter();
      if (payload.scanType) {
        paginationBuilder.setScanType(payload.scanType);
      }
      if (payload.id) {
        paginationBuilder.addFilterWithoutOperator(`${payload.analyticsType}Id`, payload.id);
      }
      ApiFactory.getScan()
        .getScansByDate(paginationBuilder)
        .then((response) => {
          commit('UPDATE_ANALYTICS_SCANS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_CAMPAIGN_STATS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getAnalyticsCampaignStats(payload)
        .then((response) => {
          commit('UPDATE_ANALYTICS_CAMPAIGN_STATS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_METRICS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanStat(payload)
        .then((response: IAnalyticsScanMetrics) => {
          commit('UPDATE_ANALYTICS_SCAN_METRICS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};
