import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import { ISearchablePage } from '@connect-saas/modules/search';
import { IPage } from '@connect-saas/types';
import { MutationTree } from 'vuex';
import { IPageState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IPageState> = {
  UPDATE_PAGINATED_PAGES(state: IPageState, paginated_pages: PaginatedResponse<ISearchablePage>) {
    const paginatedList = paginated_pages;
    if (
      state.update_selected_page &&
      !paginated_pages.resources?.some(
        (page: ISearchablePage): boolean => page.id === state.update_selected_page.id
      )
    ) {
      paginatedList.resources?.unshift(state.update_selected_page);
    }
    state.paginated_pages = paginatedList;
    state.update_selected_page = null;
  },
  SELECTED_PAGE(state: IPageState, selected_page: IPage) {
    state.selected_page = selected_page;
  },
  UPDATE_SELECTED_PAGE(state: IPageState, update_selected_page: IPage) {
    state.update_selected_page = update_selected_page;
  },
  UPDATE_SELECTED_PAGE_FOR_LOOKUP(state: IPageState, update_selected_page_for_lookup: IPage) {
    state.update_selected_page_for_lookup = update_selected_page_for_lookup;
  },
  NEWLY_CREATED_PAGE_FOR_EDIT_QR(state: IPageState, newly_created_page_for_edit_qr: IPage) {
    state.newly_created_page_for_edit_qr = newly_created_page_for_edit_qr;
  },
  PAGE_CHANGING(state: IPageState, page_changing: boolean) {
    state.page_changing = page_changing;
  }
};
